import React from 'react'
import create from 'use-persisted-state'

/**
 * Simple wrapper around use-persisted-state to setup initial state once
 * when creating hook instead of setting it every time.
 *
 * Also with proper generic typing which DefinitelyTyped one is lacking.
 *
 * @param key
 * @param initialState
 */
export function createStatePersistence<T>(key: string, initialState: T) {
  const hook = create(getStatePersistenceKey(key))
  return function useStatePersistence() {
    return hook(initialState) as [T, React.Dispatch<React.SetStateAction<T>>]
  }
}

export function getStatePersistenceKey(key: string) {
  return `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_STAGE}_${key}`
}
